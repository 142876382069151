import classNames from "classnames"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useWallet } from "@solana/wallet-adapter-react"
import LogRocket from "logrocket"
import styles from "./Header.module.scss"
import Search from "../Search/Search"
import {
  ContactCircle,
  Gear,
  HamburgerNav,
  Key,
  LogOut,
  Search as SearchIcon,
  Ticket,
} from "../../css/icons"
import SignInModal from "../../Authentication/SignInModal"
import {
  setUser,
  signOut,
  setUSDCBalance,
  setNumTickets,
} from "../../reducers/userSlice"

import { getTokenAccountsForUser } from "../../helpers/getTokenAccounts"
import { augmentUser } from "../../helpers/augmentUser"
import { PublicKey } from "@solana/web3.js" // eslint-disable-line no-unused-vars
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"
import { useDetectClickOutside } from "../../hooks/detectClickOutside"
import MobileSearch from "../MobileSearch/MobileSearch"
import EmailCapture from "../../Authentication/EmailCapture"
import {
  recordIterableLogin,
  recordIterableSignup,
  recordIterableUser,
} from "../../helpers/recordIterableUser"
import Button from "../Button/Button"

// todo add show gold back here
const Header = (props) => {
  const { publicKey } = useWallet()
  const adapter = useWallet()

  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [showSigninModal, setShowSigninModal] = useState(
    searchParams.get("ssi") || false
  )
  const [showCongestion, setShowCongestion] = useState(false)
  const USDCBalance = useSelector((state) => state.user.usdc)
  const numTickets = useSelector((state) => state.user.numTickets)
  const user = useSelector((state) => state.user.user)
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const mobileNavRef = useRef()
  const [showSearch, setShowSearch] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [showEmailCapture, setShowEmailCapture] = useState(false)
  const [showSportsMenu, setShowSportsMenu] = useState(false)
  const [nfl, setNfl] = useState([])
  const [nba, setNba] = useState([])
  const [mlb, setMlb] = useState([])
  const [showPerformers, setShowPerformers] = useState(false)
  const [performers, setPerformers] = useState([])

  useDetectClickOutside(mobileNavRef, () => {
    setMobileNavOpen(false)
    document.body.style.position = "unset"
  })

  const getTokenBalance = async (adapter, user) => {
    let operator
    let publicKey
    if (adapter?.publicKey) {
      const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
      operator = new Operator("mainnet-beta", walletAdapterIdentity)
      publicKey = adapter.publicKey
    } else if (user?.publicKey) {
      operator = new Operator("mainnet-beta")
      publicKey = new PublicKey(user.publicKey)
    } else {
      return { decryptList: [], decryptListPast: [] }
    }

    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })

    let mint = "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v"
    let tokenAddress = PDA.token(new PublicKey(mint), publicKey)
    try {
      let token = await TokenAccount.init(operator, tokenAddress)
      if (USDCBalance === null) {
        if (token.balance.qty) {
          dispatch(
            setUSDCBalance(parseInt(parseInt(token.balance.qty) / 1000000))
          )
        } else {
          dispatch(setUSDCBalance(0))
        }
      }
    } catch (err) {
      if (USDCBalance === null) {
        dispatch(setUSDCBalance(0))
      }
    }
  }

  const loadWallet = async () => {
    if (!user && !adapter?.publicKey) {
      let u = localStorage.getItem("user")
      if (u) {
        let _user = JSON.parse(u)
        let { decryptList } = await getTokenAccountsForUser(adapter, _user)
        dispatch(setNumTickets(decryptList.length))
        getTokenBalance(adapter, _user, "USDC")
      }
    } else {
      let { decryptList } = await getTokenAccountsForUser(adapter, user)
      dispatch(setNumTickets(decryptList.length))
      getTokenBalance(adapter, user, "USDC")
    }
  }

  const setUserWithUuid = async (u) => {
    u = await augmentUser(u)
    dispatch(setUser(u))
  }

  useEffect(() => {
    setInitialUtms()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.loginMethod === "phone") {
      console.log("User already logged in with phone")
      return
    }
    if (publicKey) {
      if (window.publicKey && window.publicKey !== publicKey.toString()) {
        window.location.reload()
      } else {
        window.publicKey = publicKey.toString()
      }
    }
  }, [publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  // try to load user from localstorage... probably belongs somewhere else
  useEffect(() => {
    let u = localStorage.getItem("user")
    if (u) {
      let _user = JSON.parse(u)
      if (
        localStorage.getItem("campaign_name") ||
        localStorage.getItem("phantom_browser")
      ) {
        _user.authenticated = true
      }
      setUserWithUuid(_user)
      loadWallet()
    }

    getPerformersByLeague("nfl", setNfl)
    getPerformersByLeague("nba", setNba)
    getPerformersByLeague("mlb", setMlb)
    getMusicPerformers()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // try to load user from localstorage... probably belongs somewhere else
  useEffect(() => {
    if (user?.loginMethod === "phone") {
      recordPhoneUser()
    }
  }, [user?.loginMethod]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.loginMethod === "phone") {
      console.log("User already logged in with phone")
      return
    }
    if (publicKey) {
      // user is loggged in with wallet
      document.body.style.removeProperty("overflow")

      let authenticated = false
      if (
        localStorage.getItem(
          "campaign_name" || localStorage.getItem("phantom_browser")
        )
      ) {
        authenticated = true
      }
      let u
      if (authenticated) {
        u = {
          publicKey: publicKey.toString(),
          loginMethod: "wallet",
          authenticated: authenticated,
        }
        setUserWithUuid(u)
      } else {
        u = {
          publicKey: publicKey.toString(),
          loginMethod: "wallet",
        }
        setUserWithUuid(u)
      }
      loadWallet()
      LogRocket.track(publicKey.toString())
      recordUser(u)
    } else {
      if (user?.loginMethod === "wallet") {
        dispatch(signOut())
      }
    }
  }, [publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (window.CLICKED_REDEEM) {
      console.log("clicked redeem", window.CLICKED_REDEEM)
    }
  }, [window.CLICKED_REDEEM])

  const setInitialUtms = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      let utm_medium = urlParams.get("utm_medium")
      let utm_source = urlParams.get("utm_source")
      let utm_campaign = urlParams.get("utm_campaign")

      if (utm_medium) {
        await localStorage.setItem("utm_medium", utm_medium)
      }
      if (utm_source) {
        await localStorage.setItem("utm_source", utm_source)
      }
      if (utm_campaign) {
        await localStorage.setItem("utm_campaign", utm_campaign)
      }
    } catch (err) {
      console.log("Error setting utms", err)
    }
  }

  // pass u directly into here since its not always ready from
  // redux at this time
  const recordUser = async (u) => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/record-user`
    let headers = {
      "Content-Type": "application/json",
    }
    let data = {
      wallet: publicKey.toString(),
      wallet_type: adapter?.wallet.adapter.name,
    }

    if (window.INITIAL_CAMPAIGN) {
      data["initial_campaign"] = window.INITIAL_CAMPAIGN
    }

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      let json = await resp.json()
      let u2 = JSON.parse(JSON.stringify(u))
      u2.first_name = json.first_name
      u2.last_name = json.last_name
      u2.email = json.email
      u2.uuid = json.xp_user_uuid
      if (!json.xp_user_uuid) {
        u2.uuid = json.uuid
      }
      u2.loginMethod = "wallet"
      u2.ipInferredCity = json.ipInferredCity
      u2.ipInferredMetro = json.ipInferredMetro
      u2.ip_geo_location_geo_location = json.ip_geo_location

      if (
        localStorage.getItem("campaign_name") ||
        localStorage.getItem("phantom_browser")
      ) {
        u2.authenticated = true
      }
      // console.log(
      //   props.shouldCaptureEmail,
      //   window.CLICKED_REDEEM,
      //   "IN HEADER U2"
      // )
      // if (!u2.email && props.shouldCaptureEmail && window.CLICKED_REDEEM) {
      //   setShowEmailCapture(true)
      // }

      if (!u2.email && props.startGame && window.START_GAME_AFTER_LOGIN) {
        setShowEmailCapture(true)
      } else {
        if (window.START_GAME_AFTER_LOGIN && props.startGame) {
          window.START_GAME_AFTER_LOGIN = false
          props.startGame()
        }
      }
      u2 = await augmentUser(u2)
      dispatch(setUser(u2))
      recordIterableUser(u2, adapter)

      if (!window.WALLET_USER_SIGNED_IN) {
        if (json.created_at > new Date().getTime() - 1000 * 60 * 60 * 24) {
          recordIterableSignup(u2)
        } else {
          recordIterableLogin(u2)
        }
        window.WALLET_USER_SIGNED_IN = true
      }
    } catch (err) {
      console.log("Error recording user", err)
    }
  }

  const recordPhoneUser = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/record-user`
    let headers = {
      "Content-Type": "application/json",
    }
    let data = {
      wallet: user.publicKey.toString(),
      wallet_type: "phone",
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      let json = await resp.json()
      let u2 = JSON.parse(JSON.stringify(user))
      u2.first_name = json.first_name
      u2.last_name = json.last_name
      u2.email = json.email
      u2.uuid = json.uuid
      u2.loginMethod = "phone"
      u2.ipInferredCity = json.ipInferredCity
      u2.ipInferredMetro = json.ipInferredMetro
      u2.ip_geo_location_geo_location = json.ip_geo_location

      if (
        localStorage.getItem("campaign_name") ||
        localStorage.getItem("phantom_browser")
      ) {
        u2.authenticated = true
      }

      u2 = await augmentUser(u2)
      dispatch(setUser(u2))
      recordIterableUser(u2, adapter)

      if (!window.PHONE_USER_SIGNED_IN) {
        if (json.created_at > new Date().getTime() - 1000 * 60 * 60 * 24) {
          recordIterableSignup(u2)
        } else {
          recordIterableLogin(u2)
        }
        window.PHONE_USER_SIGNED_IN = true
      }
      if (!u2.email) {
        setShowEmailCapture(true)
      }
      localStorage.setItem("user", JSON.stringify(u2))
    } catch (err) {
      console.log("Error recording user", err)
    }
  }

  const ref = useRef()

  const publicKeyTruncated = () => {
    if (user?.publicKey) {
      return `${user.publicKey.slice(0, 4)}...${user.publicKey.slice(-4)}`
    }
  }

  const getPerformersByLeague = async (league, callback) => {
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/performers-by-league`
    const params = { league }

    console.log(params, "params in performers by league")
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      })
      resp = await resp.json()

      callback(resp.performers)

      // return resp.events
    } catch (err) {
      console.log(err, "error")
    }
  }

  const getMusicPerformers = async () => {
    const url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/featured-performers`

    try {
      let resp = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      })
      resp = await resp.json()

      console.log(resp, " the resp")

      setPerformers(resp)

      // return resp.events
    } catch (err) {
      console.log(err, "error")
    }
  }

  return (
    <>
      {process.env.REACT_APP_SKIN !== "ticketnetwork" && !showCongestion && (
        <div className={styles.disclaimerBanner}>
          XP is a resale exchange. Prices may vary from face value.
        </div>
      )}

      {showCongestion && (
        <div className={styles.disclaimerBanner} style={{ color: "red" }}>
          Pardon our latency, XP is experiencing high transaction volume. It may
          take multiple attempts to complete your transaction.
        </div>
      )}

      <div className={styles.blackHeader} id="header" ref={ref}>
        <div className={styles.headerLeft}>
          <img
            className={styles.headerLogo}
            alt="logo"
            style={
              process.env.REACT_APP_SKIN == "ticketnetwork"
                ? { curosr: "pointer", width: "unset", height: "45px" }
                : { cursor: "pointer" }
            }
            onClick={() => navigate("/")}
            src={
              process.env.REACT_APP_SKIN == "ticketnetwork"
                ? "https://cdn.hngr.co/tamperproof/goldcoast-powered-by-xp-logo.png"
                : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Logo.png"
            }
          />
          {window.innerWidth > 960 &&
          (location.pathname !== "/" || props.showSearchInHeader) ? (
            <Search inHeader={true} />
          ) : (
            <p className={styles.tagline}>Upfront pricing. No hidden fees</p>
          )}
        </div>
        {window.innerWidth > 960 && (
          <>
            <div className={styles.headerRight}>
              <div
                className={styles.headerMenuContainer}
                // onMouseEnter={() => setShowSportsMenu(true)}
                // onMouseLeave={() => setShowSportsMenu(false)}
              >
                <div
                  className={styles.headerLink}
                  onClick={() =>
                    navigate("/events?eventType=sports&nearby=true")
                  }
                >
                  Sports
                </div>
                {showSportsMenu && (
                  <div className={styles.listMenu}>
                    <h2>Sports</h2>
                    <div className={styles.sportsContainer}>
                      <div className={styles.sportsColumn}>
                        <h3>NFL</h3>
                        {nfl.map((performer, idx) => {
                          if (idx > 10) return null
                          return (
                            <div
                              className={styles.sport}
                              key={idx}
                              onClick={() =>
                                navigate(`/events?performer=${performer.id}`)
                              }
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                        <Button
                          className={styles.button}
                          onClick={() => navigate("/events/sports/nfl")}
                          variant="secondary"
                        >
                          View All NFL
                        </Button>
                      </div>
                      <div className={styles.sportsColumn}>
                        <h3>NBA</h3>
                        {nba.map((performer, idx) => {
                          if (idx > 10) return null
                          return (
                            <div
                              className={styles.sport}
                              key={idx}
                              onClick={() =>
                                navigate(`/events?performer=${performer.id}`)
                              }
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                        <Button
                          className={styles.button}
                          onClick={() => navigate("/events/sports/nba")}
                          variant="secondary"
                        >
                          View All NBA
                        </Button>
                      </div>
                      <div className={styles.sportsColumn}>
                        <h3>MLB</h3>
                        {mlb.map((performer, idx) => {
                          if (idx > 10) return null
                          return (
                            <div
                              className={styles.sport}
                              key={idx}
                              onClick={() =>
                                navigate(`/events?performer=${performer.id}`)
                              }
                            >
                              {performer.name}
                            </div>
                          )
                        })}
                        <Button
                          className={styles.button}
                          onClick={() => navigate("/events/sports/mlb")}
                          variant="secondary"
                        >
                          View All MLB
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={styles.headerLink}
                onClick={() =>
                  navigate("/events?eventType=concerts&nearby=true")
                }
                // onMouseEnter={() => setShowPerformers(true)}
                // onMouseLeave={() => setShowPerformers(false)}
              >
                Concerts
              </div>
              {showPerformers && (
                <div className={styles.listMenu}>
                  <h2>Concerts</h2>
                  <div className={styles.sportsContainer}>
                    <div className={styles.sportsColumn}>
                      {/* <h3>NFL</h3> */}
                      {performers.map((performer, idx) => {
                        if (idx > 10) return null
                        return (
                          <div
                            className={styles.sport}
                            key={idx}
                            onClick={() =>
                              navigate(`/events?performer=${performer.id}`)
                            }
                          >
                            {performer.name}
                          </div>
                        )
                      })}
                      <Button
                        className={styles.button}
                        onClick={() => navigate("/events/event_type/concerts")}
                        variant="secondary"
                      >
                        View All Concert Tickets
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={styles.headerLink}
                onClick={() => navigate("/events?eventType=comedy&nearby=true")}
              >
                Comedy
              </div>
              <div
                className={styles.headerLink}
                onClick={() =>
                  navigate("/events?eventType=theater&nearby=true")
                }
              >
                Theater
              </div>
              {/* <a
                href="https://faint-ballcap-e83.notion.site/WELCOME-TO-XP-6176fd2899b84ea48c7532558594eea4"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.headerLink}>Our Mission</div>
              </a> */}

              <div
                className={classNames(
                  styles.menuContainer,
                  showMenu && styles.menuContainerOpen
                )}
                onMouseEnter={() => {
                  if (user?.publicKey) {
                    setShowMenu(true)
                  }
                }}
                onMouseLeave={() => {
                  if (user?.publicKey) {
                    setShowMenu(false)
                  }
                }}
              >
                {user?.publicKey ? (
                  <div
                    className={classNames(
                      styles.userMenu,
                      styles.userMenuLoggedIn
                    )}
                  >
                    <ContactCircle />
                    {user?.loginMethod === "phone"
                      ? user?.phone
                      : publicKeyTruncated()}
                  </div>
                ) : (
                  <div
                    className={styles.userMenu}
                    onClick={() => setShowSigninModal(true)}
                  >
                    {" "}
                    Sign Up / Log In
                  </div>
                )}
                {showMenu && (
                  <div className={styles.userMenuContainer}>
                    <div>
                      {/* <div className={styles.usdcButton}>
                        <span>USDC Balance</span>
                        <span className={styles.usdcBalance}>
                          ${USDCBalance}
                        </span>
                      </div> */}
                      <div
                        className={styles.menuButton}
                        onClick={() => navigate("/holder/tickets")}
                      >
                        <span>My Tickets</span>
                        <div className={styles.count}>
                          <span className={styles.number}>{numTickets}</span>{" "}
                          <Ticket />
                        </div>
                      </div>
                      <div
                        className={styles.menuButton}
                        onClick={() => navigate("/account/rewards")}
                      >
                        <span>My Rewards</span>
                      </div>
                      <div
                        className={styles.menuButton}
                        onClick={() => navigate("/account")}
                      >
                        <span>Manage Account</span>
                        <Gear />
                      </div>
                      <div
                        className={classNames(styles.menuButton, styles.logOut)}
                        onClick={() => {
                          setShowMenu(false)
                          adapter?.disconnect()
                          window.WALLET_USER_SIGNED_IN = false // for iterable tracking
                          if (user?.loginMethod === "phone") {
                            dispatch(signOut())
                            window.PHONE_USER_SIGNED_IN = false // for iterable tracking
                          }
                          if (location.pathname !== "/") {
                            navigate("/")
                          }
                        }}
                      >
                        <span>Log Out</span>
                        <LogOut />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {window.innerWidth <= 960 && (
          <>
            <div
              className={classNames(styles.headerRight, styles.mobileNavClosed)}
            >
              <>
                {showSearch ? (
                  <MobileSearch close={() => setShowSearch(false)} />
                ) : (
                  <SearchIcon
                    className={styles.search}
                    onClick={() => {
                      setShowSearch(true)
                    }}
                  />
                )}
              </>
              <HamburgerNav
                className={styles.hamburger}
                onClick={() => {
                  setMobileNavOpen(true)
                  document.body.style.position = "fixed"
                }}
              />
            </div>
          </>
        )}
      </div>
      <div
        ref={mobileNavRef}
        className={classNames(
          styles.mobileNavContainer,
          mobileNavOpen && styles.mobileNavContainerOpen
        )}
      >
        <div
          className={classNames(
            styles.menuContainer,
            showMenu && styles.menuContainerOpen
          )}
        >
          {user?.publicKey ? (
            <div>
              <div className={styles.userMenu}>
                <ContactCircle />
                {user?.loginMethod === "phone"
                  ? user?.phone
                  : publicKeyTruncated()}
              </div>
              <div className={styles.userMenuContainer}>
                <div>
                  {/* <div className={styles.usdcButton}>
                    <span>USDC Balance</span>
                    <span className={styles.usdcBalance}>${USDCBalance}</span>
                  </div> */}
                  <div
                    className={styles.menuButton}
                    onClick={() => navigate("/holder/tickets")}
                  >
                    <span>My Tickets</span>
                    <div className={styles.count}>
                      <span className={styles.number}>{numTickets}</span>{" "}
                      <Ticket />
                    </div>
                  </div>
                  <div
                    className={styles.menuButton}
                    onClick={() => navigate("/account/rewards")}
                  >
                    <span>My Rewards</span>
                    <div className={styles.count}>
                      <Ticket />
                    </div>
                  </div>
                  <div
                    className={styles.menuButton}
                    onClick={() => navigate("/account")}
                  >
                    <span>Manage Account</span>
                    <Gear />
                  </div>
                  <div
                    className={classNames(styles.menuButton, styles.logOut)}
                    onClick={() => {
                      setShowMenu(false)
                      adapter?.disconnect()
                      window.WALLET_USER_SIGNED_IN = false // for iterable tracking
                      if (user?.loginMethod === "phone") {
                        dispatch(signOut())
                        window.PHONE_USER_SIGNED_IN = false // for iterable tracking
                      }
                      if (location.pathname !== "/") {
                        navigate("/")
                      }
                    }}
                  >
                    <span>Log Out</span>
                    <LogOut />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div
                className={classNames(styles.userMenu, styles.userMenuMobile)}
                onClick={() => {
                  setShowSigninModal(true)
                  setMobileNavOpen(false)
                }}
              >
                Sign Up / Log In
              </div>
              <div className={styles.loggedOutInfo}>
                <Key />
                <div>Sign up or log in to access full features</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showSigninModal && (
        <SignInModal onClose={() => setShowSigninModal(false)} />
      )}
      {/* {showEmailCapture &&
        (window.location.href.indexOf("hannah") > -1 ||
          window.location.href.includes("redeem") > -1) && ( // chANGE THIS
          <EmailCapture
            startGame={props.startGame}
            onClose={() => setShowEmailCapture(false)}
          />
        )} */}
    </>
  )
}

export default Header
