import { useEffect, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import classNames from "classnames"
import { useParams } from "react-router-dom"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  PDA,
} from "@captainxyz/solana-core"

// DO NOT DELETE THESE OR THE WHOLE THING WON'T WORK FOR SOME REASON
import {
  isTamperproofHolder, // eslint-disable-line no-unused-vars
  isTamperproofOracle, // eslint-disable-line no-unused-vars
} from "@captainxyz/tamperproof"

import { PublicKey } from "@solana/web3.js"
import { Layout, ProgressBar } from "../components"
import Loading from "../Loading/Loading"
import ErrorModal from "./ErrorModal"
import UnsealModal from "./UnsealModal"
import { useSelector } from "react-redux"
import styles from "./NFT.module.scss"
import { CheckCircleGreenTransparent, GoldStar, USDC } from "../css/icons"

import postToSlack from "../postToSlack"
import * as Sentry from "@sentry/browser"
import SendToAnotherAttendeeModal from "./SendToAnotherAttendeeModal"
import { formatCents } from "../helpers/money"
import { Blurhash } from "react-blurhash"
import { recordIterablePageview } from "../helpers/recordIterableUser"
import { trackFbPageView } from "../helpers/facebook"

const NFT = () => {
  const [selectedEvent, setSelectedEvent] = useState({})
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [token, setToken] = useState(false)
  const [sealed, setSealed] = useState(null)
  const [unsealed, setUnsealed] = useState(null)
  const [showUnsealModal, setShowUnsealModal] = useState(false)
  const [showSendToAnotherAttendeeModal, setShowSendToAnotherAttendeeModal] =
    useState(false)
  const [showUnsealingModal, setShowUnsealingModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [unsealSuccess, setUnsealSuccess] = useState(false)
  const [price, setPrice] = useState(null)
  const [order, setOrder] = useState(null)
  const user = useSelector((state) => state.user.user)
  //const selectedEvent = useSelector((state) => state.events.selectedEvent)
  const [email, setEmail] = useState(user?.email || null)

  const { mint } = useParams()
  const adapter = useWallet()

  const mintString = mint.toString().split("?")[0]

  let uuid = null
  // uuid
  if (mintString.indexOf("-") > -1) {
    uuid = mintString
  }

  const statusQuery = new URLSearchParams(window.location.search).get("status")
  console.log(statusQuery)

  // on load, check for success param to show success banner
  // on load, fetch event from nft mint

  useEffect(() => {
    window.scrollTo(0, 0)
    getTicketStatus()
    getOrder()
    //dispatch(getEventFromMint(mintString))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.publicKey) {
      getToken()
    }
    if (user?.first_name) {
      setFirstName(user?.first_name)
    }
    if (user?.last_name) {
      setLastName(user?.last_name)
    }
    if (user?.email) {
      setEmail(user?.email)
    }

    if (user) {
      recordIterablePageview(
        user,
        window.location.pathname,
        selectedEvent?.title
      )
      trackFbPageView(user)
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter.signMessage) {
      if (adapter.publicKey) {
        postToSlack(
          `${adapter.publicKey.toString()} is viewing NFT ${mintString}`
        )
      }
    }
  }, [adapter.signMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  // const checkForRebateToken = async () => {
  //   if (!adapter?.publicKey) return false
  //   const hasToken = await hasSolanaChip({
  //     adapter: adapter,
  //     orderId: order.id,
  //   })
  //   setHasRebateToken(hasToken)
  // }

  const getTicketStatus = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/ticket-is-pending`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      mint: mintString,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      if (resp.pending) {
        setEmail(resp.email)
        setPrice(resp.price)
      } else {
        setPrice(resp.price)
      }
    } catch (err) {
      console.log(err, "Error in get status")
    }
  }

  const getOrder = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/get-order`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      mint: mintString,
    }

    if (uuid) {
      params = { uuid: uuid }
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      setOrder(resp)
      setSelectedEvent(resp.event)
    } catch (err) {
      console.log(err, "Error in get-order")
      // alert("err")
    }
  }

  console.log(order, selectedEvent)

  const getToken = async () => {
    if (!user?.publicKey) return

    if (uuid) {
      setToken({})
      setUnsealed(true)
      return
    } else {
      setConfig("mainnet-beta", {
        rpcEndpoint: process.env.REACT_APP_RPC,
      })
      let operator
      if (user.loginMethod === "wallet") {
        const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
        operator = new Operator("mainnet-beta", walletAdapterIdentity)
      } else {
        operator = new Operator("mainnet-beta")
      }

      let pubKey = new PublicKey(user.publicKey)
      let tokenAddress = PDA.token(new PublicKey(mintString), pubKey)
      let token
      try {
        token = await TokenAccount.init(operator, tokenAddress)
      } catch (err) {
        console.log("token not available yet...")
        setTimeout(() => getToken(), 300)
        return
      }

      if (token.canRequestUnsealing) {
        // eslint-disable-next-line eqeqeq
        if (token.mint.address.toString() == mintString) {
          setToken(token)
          setSealed(true)
          return
        }
      }
      if (token.canDecrypt) {
        // eslint-disable-next-line eqeqeq
        if (token.mint.address.toString() == mintString) {
          setToken(token)
          setUnsealed(true)
          return
        }
      }

      // instant transfer
      if (!token.metadata._json.secret) {
        setToken(token)
        setUnsealed(true)
        return
      }
    }
  }

  const unsealWithAdapter = async (email) => {
    // if we somehow got here with an already unsealed one
    if (token.canDecrypt) {
      setShowUnsealingModal(false)
      return
    }

    let a
    try {
      a = await token?.requestUnsealing()
    } catch (err) {
      Sentry.captureException(err)
      if (err.message && err.message.indexOf("has not been authorized") > -1) {
        alert("There may be an issue with Phantom. Please refresh the page.")
      }
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/tamperproofRequestReveal`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      wallet: adapter.publicKey.toString(),
      sig: a.signature,
      mint: token.mint.address.toString(),
      oracle_id: token.metadata._json.oracle_id,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      resp = await resp.json()
      if (resp.success) {
        await getToken()
        setUnsealSuccess(true)
        setShowUnsealingModal(false)
        postToSlack(
          `${adapter.publicKey.toString()} succesfully unsealed for ${mintString}`
        )
        // todo: make these the same variable, because having two is gross
        setUnsealed(true)
        setSealed(false)
        window.scrollTo(0, 0)
        decrypt(email)
      } else {
        postToSlack(
          `${adapter.publicKey.toString()} captured unsealed error for ${mintString}`
        )
        setShowUnsealingModal(false)
        setShowErrorModal(true)
      }
    } catch (err) {
      console.log("Error unsealing", err)
      postToSlack(
        `${adapter.publicKey.toString()} unkown unsealed error for ${mintString}`
      )
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const unsealServerSide = async (email) => {
    let data = {
      diner_uuid: user.diner_uuid,
      mint: mintString,
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexRequestUnseal`

    let headers = {
      "Content-Type": "application/json",
    }

    let resp
    try {
      resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
    } catch (err) {
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    if (resp.success) {
      setUnsealSuccess(true)
      setShowUnsealingModal(false)
      postToSlack(`${user.publicKey} succesfully unsealed for ${mintString}`)
      setUnsealed(true)
      setSealed(false)
      window.scrollTo(0, 0)
      decrypt(email)
    } else {
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const unseal = async () => {
    setShowErrorModal(false)
    setShowUnsealModal(false)
    setShowUnsealingModal(true)
    postToSlack(`${user.publicKey} is requesting unseal for ${mintString}`)
    if (user.loginMethod === "wallet") unsealWithAdapter()
    else unsealServerSide()
  }

  const decrypt = async () => {
    let secret
    if (user.loginMethod === "wallet") {
      try {
        secret = await token.decrypt()
      } catch (err) {
        Sentry.captureException(err)
        if (
          err.message &&
          err.message.indexOf("has not been authorized") > -1
        ) {
          alert("There may be an issue with Phantom. Please refresh the page.")
        }
        return
      }
      postToSlack(
        `${adapter.publicKey.toString()} decrypted from nft page for ${mintString}`
      )
      submitCode(secret, email)
    } else {
      let data = {
        diner_uuid: user.diner_uuid,
        mint: mintString,
      }
      let url = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexDecrypt`

      let headers = {
        "Content-Type": "application/json",
      }

      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(data),
      })
      resp = await resp.json()
      submitCode(resp.secret, email)
    }
  }

  const submitCode = async (code, email) => {
    let url = `${process.env.REACT_APP_HNGR_API}/ticketdex/claim-code`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      code: code,
      email: email,
      mint: mintString,
      first_name: firstName,
      last_name: lastName,
      wallet: user.publicKey.toString(),
    }
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
  }

  // const calculateRebateAmount = () => {
  //   if (!price) return ""
  //   else {
  //     let rebate = 0.8 * price
  //     if (rebate > 200) rebate = 200
  //     return rebate.toFixed(2)
  //   }
  // }

  const shouldShowSellerNotes = (notes) => {
    if (
      notes === "xfer" ||
      notes === "XFER" ||
      notes === "mobile xfer" ||
      notes === "flash"
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      {showUnsealModal && (
        <UnsealModal
          close={() => {
            document.body.style.overflow = "auto"
            setShowUnsealModal(false)
          }}
          email={email}
          unseal={(email) => unseal(email)}
          setShowSendToAnotherAttendeeModal={setShowSendToAnotherAttendeeModal}
        />
      )}
      {showSendToAnotherAttendeeModal && (
        <SendToAnotherAttendeeModal
          close={() => setShowSendToAnotherAttendeeModal(false)}
          setShowUnsealModal={setShowUnsealModal}
          unseal={(email) => unseal(email)}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          token={token}
          unseal={() => unseal()}
          close={() => setShowErrorModal(false)}
        />
      )}

      <Layout className={styles.pageContainer}>
        <div className={styles.blurhashContainer}>
          {order?.event?.image_blurhash && (
            <Blurhash
              hash={order?.event?.image_blurhash}
              width={window.innerWidth}
              height={window.innerHeight}
              resolutionX={32}
              resolutionY={32}
              punch={0}
            />
          )}
          <div className={styles.blurhashOverlay} />
        </div>
        {showUnsealingModal ? (
          <>
            {unsealSuccess ? (
              <div className={styles.unsealLoading}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/unlock.gif"
                  alt="Unsealed"
                />
              </div>
            ) : (
              <div className={styles.unsealLoading}>
                <img
                  src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
                  className={styles.spinner}
                  alt="spinner"
                />
                <h1>Ticket transfer initiated</h1>
                <p>Please hold on for a moment while we send your ticket.</p>
                <p>This may take up to one minute</p>
              </div>
            )}
          </>
        ) : (
          <>
            {token && (sealed || unsealed) ? (
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.contentLeft}>
                    <div className={styles.showInfo}>
                      <img
                        className={styles.showImage}
                        src={selectedEvent?.image}
                        alt={selectedEvent?.short_title}
                      />
                      <div className={styles.showDetails}>
                        <div className={styles.showDetailsLeft}>
                          <div className={styles.date}>
                            {selectedEvent?.date_formatted?.slice(0, 3)} |{" "}
                            {selectedEvent?.date_formatted?.slice(5, 11)} |{" "}
                            {selectedEvent?.date_formatted?.split("•")[1]}
                          </div>
                          <div className={styles.title}>
                            {selectedEvent?.short_title || selectedEvent?.title}
                          </div>
                          <div className={styles.venue}>
                            {selectedEvent?.venue_name}•{" "}
                            {selectedEvent?.venue_city}•{" "}
                            {selectedEvent?.venue_state}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.seatInfoContainer}>
                      <div className={styles.seatInfo}>
                        <div className={styles.section}>
                          Section
                          <span className={styles.value}>
                            {order?.section}{" "}
                          </span>
                        </div>
                        <div className={styles.divider} />

                        <div className={styles.row}>
                          Row
                          <span className={styles.value}>{order?.row} </span>
                        </div>
                      </div>
                      <div className={styles.ticketInfoRight}>
                        <div className={styles.ticketQuantityContainer}>
                          {order?.quantity}{" "}
                          {order?.quantity > 1 ? "Tickets" : "Ticket"}
                        </div>
                      </div>
                    </div>
                    <div className={styles.xpGuarantee}>
                      <span>Backed by our Quality Xperience Guarantee</span>{" "}
                      <GoldStar />
                    </div>
                  </div>

                  <div className={styles.contentRight}>
                    {order?.in_hand_date && (
                      <div className={styles.deliveryBanner}>
                        <div className={styles.deliveryRight}>
                          <div className={styles.deliveryHeader}>
                            {order?.status === "completed" ? (
                              <span className={styles.delivered}>
                                Delivered <CheckCircleGreenTransparent />
                              </span>
                            ) : (
                              <span>Ticket Delivery By:</span>
                            )}
                          </div>

                          {order?.status !== "completed" && (
                            <div className={styles.deliveryDate}>
                              {new Date(order?.in_hand_date).toLocaleString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {(statusQuery === "creating" ||
                      order?.status === "creating") && (
                      <div
                        className={classNames(
                          styles.ticketStatusContainer,
                          !order?.in_hand_date && styles.topBorder,
                          !order?.notes && styles.bottomBorder,
                          !order?.in_hand_date && !order?.notes && styles.border
                        )}
                      >
                        <h1>Awaiting Confirmation</h1>
                        <p>
                          Your order has been placed and is awaiting
                          confirmation from the seller.
                        </p>

                        <ProgressBar
                          steps={8}
                          stepsCompleted={1}
                          labels={["Order Placed", "Confirmed", "Delivered"]}
                        />
                      </div>
                    )}
                    {(statusQuery === "pending" ||
                      statusQuery === "accepted" ||
                      order?.status === "pending" ||
                      order?.status === "accepted") && (
                      <div
                        className={classNames(
                          styles.ticketStatusContainer,
                          !order?.in_hand_date && styles.topBorder,
                          !order?.notes && styles.bottomBorder,
                          !order?.in_hand_date && !order?.notes && styles.border
                        )}
                      >
                        <h1>Tickets are on the way</h1>
                        <p>
                          Your order has been confirmed and is awaiting delivery
                          from the seller. Your tickets will be available as
                          soon as possible, generally no later than 48 hours
                          before the event.
                        </p>

                        <ProgressBar
                          steps={8}
                          stepsCompleted={4}
                          labels={["Order Placed", "Confirmed", "Delivered"]}
                        />
                      </div>
                    )}
                    {(statusQuery === "completed" ||
                      order?.status === "completed") && (
                      <div
                        className={classNames(
                          styles.ticketStatusContainer,
                          !order?.in_hand_date && styles.topBorder,
                          !order?.notes && styles.bottomBorder,
                          !order?.in_hand_date && !order?.notes && styles.border
                        )}
                      >
                        <h1 className={styles.completedHeader}>
                          Tickets Delivered
                        </h1>
                        <p>
                          Your tickets have been successfully sent to{" "}
                          {user?.email}. Enjoy the show!
                        </p>

                        <ProgressBar
                          steps={8}
                          stepsCompleted={8}
                          labels={["Order Placed", "Confirmed", "Delivered"]}
                          complete={true}
                        />
                      </div>
                    )}
                    {(statusQuery === "rejected" ||
                      statusQuery === "failed" ||
                      order?.status === "rejected" ||
                      order?.status === "failed") && (
                      <div
                        className={classNames(
                          styles.ticketStatusContainer,
                          !order?.in_hand_date && styles.topBorder,
                          !order?.notes && styles.bottomBorder,
                          !order?.in_hand_date && !order?.notes && styles.border
                        )}
                      >
                        <h1 className={styles.canceled}>Purchase canceled</h1>
                        <p className={styles.canceledP}>
                          The ticket provider has decided to decline your
                          purchase. We have provided you a full refund.
                        </p>
                      </div>
                    )}
                    {order?.seller_notes &&
                      shouldShowSellerNotes(order?.seller_notes) && (
                        <div className={styles.notes}>
                          <div className={styles.notesHeader}>Seller Notes</div>
                          <div className={styles.note}>
                            {order?.seller_notes}
                          </div>
                        </div>
                      )}
                    <div className={styles.orderMetadataContainer}>
                      <div className={styles.orderMetadataRow}>
                        <span>
                          Order Placed{" "}
                          {new Date(order?.created_at).toLocaleString("en-US", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                          })}
                        </span>
                        {/*<span>Order #{order?.id}</span>*/}
                      </div>
                      {order?.price !== order?.promo && (
                        <div className={styles.orderMetadataRow}>
                          {order?.currency === "USDC" ? (
                            <span>
                              <USDC /> Paid with USDC
                            </span>
                          ) : (
                            <span>Paid with credit card</span>
                          )}
                        </div>
                      )}
                      <div className={styles.orderPrice}>
                        <div
                          className={classNames(
                            styles.priceRow,
                            styles.subtotal
                          )}
                        >
                          <span>Subtotal</span>
                          <span>
                            {order?.quantity} x{" "}
                            {formatCents(order?.price / order?.quantity)}
                            {selectedEvent?.venue_country === "CA" && " USD"}
                          </span>
                        </div>
                        {order?.promo && (
                          <div
                            className={classNames(
                              styles.priceRow,
                              styles.discount
                            )}
                          >
                            <span>Discount</span>
                            <span>- {formatCents(order?.promo)}</span>
                          </div>
                        )}
                        <div
                          className={classNames(styles.priceRow, styles.total)}
                        >
                          <span>Total</span>
                          <span>
                            {order?.promo
                              ? formatCents(order?.price - order?.promo)
                              : formatCents(order?.price)}
                            {selectedEvent?.venue_country === "CA" && " USD"}
                          </span>
                        </div>
                        <div className={styles.disclaimer}>
                          Sales tax is included in total. There are no fees with
                          this order.
                        </div>
                      </div>
                    </div>
                    <div className={styles.help}>
                      Run into an issue?{" "}
                      <a
                        href="https://xpsupport.zendesk.com/hc/en-us/requests/new"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Contact us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Loading />
            )}
          </>
        )}
      </Layout>
    </>
  )
}

export default NFT
