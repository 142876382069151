import styles from "./DerbyHero.module.scss"
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { Button, Input } from "../components"
import { CheckCircleGreenTransparent } from "../css/icons"
import { useNavigate } from "react-router-dom"

const DerbyHero = () => {
  const [email, setEmail] = useState("")
  const [emailSubmitted, setEmailSubmitted] = useState(false)
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()

  // WHEN WE'RE READY TO TURN THE GAME ON UNCOMMENT THIS
  // useEffect(() => {
  //   history.push("/g/derby/play")
  // }, [])

  const handleSubmit = async () => {
    console.log("handleSubmit", email)
    let url = `${process.env.REACT_APP_HNGR_API}/api/xp/record-marketing-user`
    let params = {
      wallet: user.user?.publicKey,
      email: email,
      url: window.location.href,
      source: "derby",
    }
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    if (resp.ok) {
      let data = await resp.json()
      console.log(data)
      setEmailSubmitted(true)
    }
  }

  const calculateTimeLeft = () => {
    // const year = new Date().getFullYear()
    const difference = +new Date(`04/24/2024`) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearTimeout(timer)
  })

  const timerComponents = []

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return
    }

    timerComponents.push(
      <>
        <div className={styles.timeBox}>
          <span className={styles.time}>
            {timeLeft[interval]
              ? String(timeLeft[interval]).padStart(2, "0")
              : "00"}
          </span>
          <span className={classNames(styles.heroSubtitle, styles.timeLabel)}>
            {interval}{" "}
          </span>
        </div>
        {interval !== "minutes" && <span className={styles.time}>:</span>}
      </>
    )
  })

  return (
    <div className={styles.heroSection}>
      <img
        alt="XP Derby"
        className={styles.derbyLogo}
        src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/XP+Derby+Logo+Glow.png"
      />
      <p className={styles.heroSubtitle}>Play for the chance to win up to</p>
      <h1 className={styles.prize}>$50,000</h1>
      <h2 className={styles.prizeSubtitle}>in prizes</h2>

      <div className={styles.instructionsBox}>
        <h2 className={styles.howItWorks}>How it works</h2>
        <p className={styles.instructions}>
          1. Claim your 5 derby horse game pieces
        </p>
        <p className={styles.instructions}>2. Arrange your lineup</p>
        <p className={styles.instructions}>3. Watch the race and win big</p>
        <p className={styles.instructions}>
          Make your selections from April 27 - May 4
        </p>
      </div>
      <div className={styles.formContainer}>
        {emailSubmitted ? (
          <>
            <div className={styles.successContainer}>
              <CheckCircleGreenTransparent />
              <div>
                <h2 className={styles.successHeader}>Welcome to the Club!</h2>
                <p className={styles.success}>
                  Thank you for submitting your email! We'll be in touch soon
                  when it’s time to make your selections.
                </p>
              </div>
            </div>
            <Button
              className={styles.button}
              variant="blue"
              fullWidth
              onClick={() => {
                navigate("/events")
              }}
            >
              Browse Events
            </Button>
          </>
        ) : (
          <>
            <div className={styles.formLabel}>Get notified when it starts</div>
            <Input
              placeholder="Email Address"
              className={styles.input}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <Button
              variant="beige"
              className={styles.button}
              fullWidth
              onClick={() => {
                handleSubmit()
              }}
            >
              Sign Up To Play
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default DerbyHero
