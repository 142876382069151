import { Button, Layout, SeatMap, SeatMapTevo, Select } from "../components"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import styles from "./SeatPreview.module.scss"
import { AngleLeft } from "../css/icons"
import { getTicketGroupById } from "../reducers/ticketSlice"
import { getEventById } from "../reducers/eventSlice"
import { getCoupon } from "../helpers/getTokenAccounts"
import classNames from "classnames"
import { Blurhash } from "react-blurhash"

const { formatCents } = require("../helpers/money")

const SeatPreview = ({ onClick, updateQuantity, ticketGroup }) => {
  const user = useSelector((state) => state.user.user)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const event = useSelector((state) => state.events.selectedEvent)

  const query = new URLSearchParams(window.location.search)
  const quantity = query.get("quantity")
  const [selectedQuantity, setSelectedQuantity] = useState(quantity || 2)
  const [coupon, setCoupon] = useState(null)

  useEffect(() => {
    dispatch(getEventById(params.event_id))
    dispatch(
      getTicketGroupById({
        ticket_group_vendor_id: params.ticket_group_vendor_id,
        event_id: params.event_id,
      })
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateQuantity(selectedQuantity)
  }, [selectedQuantity]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      checkForCoupon()
    }
  }, [user?.publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  function getVisibleHeight() {
    // Get the height of the window
    var windowHeight = window.innerHeight

    // Get the height of the browser UI chrome on iOS
    var iosChromeHeight = windowHeight - document.documentElement.clientHeight
    // Calculate the visible height by subtracting the UI chrome height from the window height
    var visibleHeight = windowHeight - iosChromeHeight

    return visibleHeight
  }

  const selectedEvent = useSelector((state) => state.events.selectedEvent)

  let mapVendor, mapConfig
  if (selectedEvent?.seatmap_id_3ddv) {
    mapVendor = "3ddv"
    mapConfig = {
      venueId: selectedEvent.seatmap_id_3ddv,
    }
  } else if (
    selectedEvent?.tevo_venue_id &&
    selectedEvent?.tevo_venue_config_id
  ) {
    mapVendor = "tevo"
    mapConfig = {
      venueId: selectedEvent?.tevo_venue_id,
      configurationId: selectedEvent?.tevo_venue_config_id,
    }
  }

  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    )

  let ticketGroupFormatted = {
    id: ticketGroup?.id,
    section_vendor: ticketGroup?.section_vendor || "",
    cost: ticketGroup?.cost,
  }

  const checkForCoupon = async () => {
    if (window.checkedForCouponInSeatPreview) return

    window.checkedForCouponInSeatPreview = true
    let c = await getCoupon(user, 999999999)
    if (c?.coupon) {
      setCoupon(c)
    }
  }


  const formatSeatRange = (seats) => {
    if(!seats) return ''
    let firstSeat = seats[0]
    let lastSeat = seats[seats.length - 1]
    return `${firstSeat} - ${lastSeat}`
  }    

  return (
    <Layout
      className={styles.container}
      contentClassName={styles.layoutContainer}
      style={{ height: getVisibleHeight() }}
      contentStyle={{ height: getVisibleHeight() }}
      noScroll
      showHeader={window.innerWidth > 960}
    >
      <div className={styles.blurhashContainer}>
        {event?.image_blurhash && (
          <Blurhash
            hash={event?.image_blurhash}
            width={window.innerWidth}
            height={window.innerHeight}
            resolutionX={32}
            resolutionY={32}
            punch={0}
          />
        )}
        <div className={styles.blurhashOverlay} />
      </div>
      <div className={styles.containerInner} id="containerInner">
        <div className={styles.leftColumn}>
          <div className={styles.back} onClick={() => navigate(-1)}>
            <AngleLeft />
            <span>Back</span>
          </div>
          <div className={styles.showInfo}>
            <picture>
              <source srcset={event?.image_avif} type="image/avif" />
              <source srcset={event?.image_webp} type="image/webp" />
              <img src={selectedEvent?.image} alt={selectedEvent?.title} />
            </picture>
            <div className={styles.showInfoHeader}>
              <div className={styles.showInfoContent}>
                <div className={styles.date}>
                  {selectedEvent?.date_formatted
                    ?.replace(",", " |")
                    .replace("•", " |")}
                </div>
                <div className={styles.title}>
                  {selectedEvent?.short_title || selectedEvent?.title}
                </div>
                <div className={styles.venue}>
                  {selectedEvent?.venue_name}, {selectedEvent?.venue_city},{" "}
                  {selectedEvent?.venue_state}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.seatInfoContainer}>
            <div className={styles.seatInfo}>
              <div className={styles.section}>
                Section
                <span className={styles.value}>
                  {ticketGroup?.section.name}
                </span>
              </div>
              <div className={styles.divider} />

              <div className={styles.row}>
                Row
                <span className={styles.value}>{ticketGroup?.row.name}</span>
              </div>

              {selectedEvent?.venue_state == 'MD' && (
                <>
                  <div className={styles.divider} />

                  <div className={styles.row}>
                    Seats 
                    <span className={styles.value}>{formatSeatRange(ticketGroup?.seats)}</span>
                  </div>
                </>
              )}

            </div>
            <div className={styles.ticketQuantityContainer}>
              <span>Ticket Quantity</span>
              <div className={styles.cardQuantitySelect}>
                <div className={styles.selector}>
                  <Select
                    options={ticketGroup?.splits}
                    selected={selectedQuantity}
                    setSelected={(value) => setSelectedQuantity(value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {coupon && (
            <div className={styles.couponContainer}>
              <div className={styles.couponHeader}>Reward Detected 💎</div>
              <div>apply it at checkout</div>
            </div>
          )}
          <div
            className={classNames(
              styles.cardBottom,
              !coupon && styles.noCoupon
            )}
          >
            <div className={styles.cardContent}>
              <div className={styles.cardTitle}>Subtotal</div>
              <div className={styles.subtotal}>
                {selectedQuantity} x ${(ticketGroup?.price / 100).toFixed(2)}
                {selectedEvent?.venue_country == 'CA' && ' USD'}
              </div>
            </div>
            <div className={styles.cardContent}>
              <div className={classNames(styles.cardTitle, styles.total)}>
                Total
              </div>
              <div className={styles.total}>
                {formatCents(ticketGroup?.price * selectedQuantity)}
                {selectedEvent?.venue_country == 'CA' && ' USD'}
              </div>
            </div>
            <div className={styles.taxDisclaimer}>
              Sales Tax and Applicable Fees included in Total
            </div>
          </div>

          {ticketGroup?.notes && ticketGroup.notes !== "XFER" && (
            <div className={classNames(styles.cardBottom, styles.noCoupon)}>
              <div className={styles.cardTitle}>Seat Details</div>
              <p className={styles.cardBody}>{ticketGroup.notes}</p>
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button
              fullWidth
              onClick={() => {
                onClick(selectedQuantity)
              }}
              loading={loading}
              className={styles.button}
            >
              Go to Checkout
            </Button>
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.venueImage}>
            {mapVendor === "3ddv" && (
              <SeatMap
                config={mapConfig}
                ticketGroups={[ticketGroup]}
                zoomedTicketGroup={ticketGroup}
                highlightedSections={[ticketGroup?.section.name]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
                translationRules={selectedEvent?.translations_3ddv}
              />
            )}
            {mapVendor === "tevo" && ticketGroup && (
              <SeatMapTevo
                config={mapConfig}
                ticketGroups={[ticketGroup]}
                zoomedTicketGroup={ticketGroup}
                highlightedSections={[]}
                handleSectionHighlight={() => {}}
                handleSectionThumbLoaded={() => {}}
                translationRules={selectedEvent?.translations_3ddv}
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SeatPreview
