import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState, useRef } from "react"
import styles from "./NewVelvet.module.scss"
import {
  EmailCapture,
  EventCarousel,
  Layout,
  Testimonials,
} from "../components"

import RedeemLoading from "../Redeem/RedeemLoading"
import classNames from "classnames"
import { useSelector, useDispatch } from "react-redux"
import {
  getFeaturedEvents,
  getNearbyEventsWithMaxPrice,
  getNearbyFeaturedEvents,
  getNearbyMusicEvents,
  getNearbySportsEvents,
} from "../reducers/eventSlice"
import CityCampaignHero from "./CityCampaignHero"
import MobileSearch from "../components/MobileSearch/MobileSearch"
import { useDetectClickOutside } from "../hooks/detectClickOutside"
import DerbyHero from "../Derby/DerbyHero"
import DefaultCampaignCard from "./DefaultCampaignCard"
import HeroCarouselCard from "./HeroCarouselCard"

import * as data from "./scratch.json"
import QualityXperienceGuarantee from "./QualityXperienceGuarantee"
import { recordIterablePageview } from "../helpers/recordIterableUser"
import { trackFbPageView } from "../helpers/facebook"
import ValueProp from "../Events/ValueProp"

const NewVelvet = () => {
  const [campaigns, setCampaigns] = useState([])
  const [defaultCampaign, setDefaultCampaign] = useState(null)
  const user = useSelector((state) => state.user?.user)
  // MAKE SURE THIS GETS CHANGED BEFORE DEPLOYMENT
  // const defaultCampaign = {}
  // defaultCampaign.json = data
  const nearby = useSelector((state) => state.events.nearby)
  const [dispenserLoading, setDispenserLoading] = useState(false)
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const [cbsa, setCbsa] = useState(null)
  const [locationName, setLocationName] = useState("")
  const [maxPrice, setMaxPrice] = useState(100)

  const [showMobileSearch, setShowMobileSearch] = useState(false)
  const ref = useRef()
  useDetectClickOutside(ref, () => setShowMobileSearch(false))

  const featuredEvents = useSelector((state) => state.events.featuredEvents)
  const featuredEventsLoaded = useSelector(
    (state) => state.events.featuredEventsLoaded
  )
  const nearbyMusicEvents = useSelector(
    (state) => state.events.nearbyMusicEvents
  )
  const nearbyMusicEventsLoaded = useSelector(
    (state) => state.events.nearbyMusicEventsLoaded
  )

  const nearbySportsEvents = useSelector(
    (state) => state.events.nearbySportsEvents
  )
  const nearbySportsEventsLoaded = useSelector(
    (state) => state.events.nearbySportsEventsLoaded
  )

  const nearbyEventsWithMaxPrice = useSelector(
    (state) => state.events.nearbyEventsWithMaxPrice
  )
  const nearbyEventsWithMaxPriceLoaded = useSelector(
    (state) => state.events.nearbyEventsWithMaxPriceLoaded
  )

  const nearbyFeaturedEvents = useSelector(
    (state) => state.events.nearbyFeaturedEvents
  )
  const nearbyFeaturedEventsLoaded = useSelector(
    (state) => state.events.nearbyFeaturedEventsLoaded
  )

  useEffect(() => {
    window.scrollTo(0, 0)
    if (params.campaign_name) {
      loadCampaignByName()
      localStorage.setItem("campaign_name", params.campaign_name)
      window.INITIAL_CAMPAIGN = params.campaign_name
    } else {
      loadAllCampaigns()
    }
    dispatch(getFeaturedEvents({ featured: true, limit: 20 }))
    dispatch(getNearbyFeaturedEvents({ nearby: true, limit: 20 }))
    dispatch(
      getNearbyMusicEvents({ nearby: true, event_type: "concert", limit: 10 })
    )
    dispatch(
      getNearbySportsEvents({ nearby: true, event_type: "sport", limit: 10 })
    )
    dispatch(
      getNearbyEventsWithMaxPrice({
        nearby: true,
        max_price: maxPrice,
        limit: 10,
      })
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user) {
      recordIterablePageview(user, "/", "Home")
      trackFbPageView(user)
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cbsa) {
      dispatch(getFeaturedEvents({ featured: true, cbsa, limit: 20 }))
      dispatch(getNearbyMusicEvents({ cbsa, event_type: "concert", limit: 10 }))
      dispatch(getNearbySportsEvents({ cbsa, event_type: "sport", limit: 10 }))
      dispatch(
        getNearbyEventsWithMaxPrice({ cbsa, max_price: maxPrice, limit: 10 })
      )
    }
  }, [cbsa]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (cbsa) {
      dispatch(
        getNearbyEventsWithMaxPrice({ cbsa, max_price: maxPrice, limit: 10 })
      )
    } else {
      dispatch(
        getNearbyEventsWithMaxPrice({
          nearby: true,
          max_price: maxPrice,
          limit: 10,
        })
      )
    }
  }, [maxPrice]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!locationName) {
      setLocationName(nearby)
    }
  }, [nearby]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // update campaign if you're visiting again
    if (params.campaign_name) {
      loadCampaignByName()
      localStorage.setItem("campaign_name", params.campaign_name)
    }
  }, [params.campaign_name]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadTicketnetworkCampaign = async () => {
    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = `/api/xp/campaigns/goldcoast`
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    const response = await fetch(url, {
      method: "post",
      headers,
    }).then((res) => res.json())

    const defaultCamp = response.campaign
    setDefaultCampaign(defaultCamp[0]) // put this back in
    return response
  }

  const loadAllCampaigns = async () => {
    if (process.env.REACT_APP_SKIN === "ticketnetwork") {
      return loadTicketnetworkCampaign()
    }

    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = "/api/xp/campaigns"
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    const response = await fetch(url, {
      method: "post",
      headers,
    }).then((res) => res.json())

    const defaultCamp = response.campaigns.find(
      (campaign) => campaign.is_default
    )
    setDefaultCampaign(defaultCamp) // put this back in

    return response
  }

  const loadCampaignByName = async () => {
    if (!params.campaign_name) return
    let headers = {
      "Content-Type": "application/json",
    }
    const endpoint = `/api/xp/campaigns/${params.campaign_name}`
    const url = `${process.env.REACT_APP_HNGR_API}${endpoint}`

    try {
      const response = await fetch(url, {
        method: "post",
        headers,
      }).then((res) => res.json())
      setDefaultCampaign(response.default[0]) // put this back in

      if (response.campaign) {
        setCampaigns(response.campaign)
      } else {
        if (!location.pathname.includes("derby")) {
          localStorage.removeItem("campaign_name")
          navigate("/")
        }
      }

      return response
    } catch (err) {
      localStorage.removeItem("campaign_name")
      navigate("/")
    }
  }

  // if there is one campaign, use that
  // if there are more than one campaign, use the default copy

  const waitForVideo = (videoId) => {
    return new Promise((resolve) => {
      let video = document.getElementById(videoId)
      if (video) {
        video.oncanplay = () => {
          return resolve(document.getElementById(videoId))
        }
      }
    })
  }

  waitForVideo("bg-basketball").then((video) => {
    const fallbackImage = document.getElementById("bg-basketball-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      if (fallbackImage) {
        fallbackImage.style.display = "block"
      }
    })
  })

  waitForVideo("bg-shield").then((video) => {
    const fallbackImage = document.getElementById("bg-shield-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      if (fallbackImage) {
        fallbackImage.style.display = "block"
      }
    })
  })

  waitForVideo("bg-bar").then((video) => {
    const fallbackImage = document.getElementById("bg-bar-fallback")
    video?.play().catch((error) => {
      console.error("Error attempting to play", error)
      video.style.display = "none"
      if (fallbackImage) {
        fallbackImage.style.display = "block"
      }
    })
  })

  return (
    <div className={styles.outerContainer}>
      {dispenserLoading && (
        <RedeemLoading
          useOneOffCopy={window.location.pathname.includes("decrypt")}
        />
      )}

      <Layout
        velvetHeader
        className={styles.velvet}
        contentClassName={styles.noPadding}
        showSearchInHeader={!defaultCampaign?.json.campaign.show_search}
        shouldCaptureEmail={true} // only used on redeem campaign pages, needs additional window variable to work
      >
        {showMobileSearch && (
          <MobileSearch
            className={styles.velvetSearch}
            close={() => setShowMobileSearch(false)}
          />
        )}
        {campaigns?.length === 1 && campaigns[0].is_city_campaign ? (
          <CityCampaignHero campaign={campaigns[0]} />
        ) : (
          <>
            {campaigns?.length === 1 ? (
              <HeroCarouselCard
                params={params}
                campaign={campaigns[0]}
                setDispenserLoading={setDispenserLoading}
              />
            ) : (
              <>
                {location.pathname.includes("derby") ? (
                  <DerbyHero />
                ) : (
                  <DefaultCampaignCard
                    campaign={defaultCampaign}
                    setShowMobileSearch={setShowMobileSearch}
                  />
                )}
              </>
            )}
          </>
        )}

        <div className={styles.carouselLoadingPlaceholder}>
          <EventCarousel
            title={"Browsing Events"}
            events={[...nearbyFeaturedEvents, ...featuredEvents]}
            link="/events?featured=true&nearby=true"
            buttonCta="Browse Trending"
            locationName={locationName}
            loaded={nearbyFeaturedEventsLoaded && featuredEventsLoaded}
            updateLocation={(location) => {
              setCbsa(location.cbsaname)
              if (location?.city && location?.state) {
                setLocationName(`${location?.city}, ${location?.state}`)
              }
            }}
          />
        </div>
        <ValueProp />
        {!(campaigns?.length === 1 && campaigns[0].is_city_campaign) && (
          <>
            <EventCarousel
              title={"Concerts"}
              events={nearbyMusicEvents}
              link="/events?event_type=concerts&nearby=true"
              loaded={nearbyMusicEventsLoaded}
              buttonCta="Browse Concerts"
              locationName={locationName}
              updateLocation={(location) => {
                setCbsa(location.cbsaname)
                if (location?.city && location?.state) {
                  setLocationName(`${location?.city}, ${location?.state}`)
                }
              }}
              showGenreList={true}
            />
          </>
        )}
        <EmailCapture
          header="Love free tickets? So do we."
          subtitle="Sign up to stay in the know around giveaways, promotions, and more."
        />
        <EventCarousel
          title={"Sports"}
          events={nearbySportsEvents}
          link="/events?event_type=sports&nearby=true"
          buttonCta="Browse Sports"
          locationName={locationName}
          loaded={nearbySportsEventsLoaded}
          updateLocation={(location) => {
            setCbsa(location.cbsaname)
            if (location?.city && location?.state) {
              setLocationName(`${location?.city}, ${location?.state}`)
            }
          }}
          showLeagueList={true}
        />
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          className={styles.heroVideo}
          width={window.innerWidth}
          id="bg-shield"
        >
          <source
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Shield_v05.mp4"
            type="video/mp4"
          />
        </video>
        <picture>
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Shield_v05.avif"
            alt="bg-shield"
            id="bg-shield-fallback"
            className={classNames(styles.heroVideo, styles.fallbackImage)}
          />
        </picture>
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          className={styles.heroVideo}
          width={window.innerWidth}
          id="bg-basketball"
        >
          <source
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Disc%2BBBall_v03ballcropped_compressed.mp4"
            type="video/mp4"
          />
        </video>
        <picture>
          <source
            srcset="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Disc%2BBBall_v03ballcropped.avif"
            type="image/avif"
          />
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Disc%2BBBall_v03ballcropped.webp"
            alt="bball"
            id="bg-basketball-fallback"
            className={classNames(styles.heroVideo, styles.fallbackImage)}
          />
        </picture>
        <QualityXperienceGuarantee />
        {defaultCampaign?.json?.testimonials?.length > 0 && (
          <Testimonials testimonials={defaultCampaign?.json.testimonials} />
        )}
        <EventCarousel
          title={"Ticket Deals"}
          events={nearbyEventsWithMaxPrice}
          link={`/events?event_type=sports&nearby=true&max_price=${maxPrice}`}
          loaded={nearbyEventsWithMaxPriceLoaded}
          buttonCta="Browse Deals"
          locationName={locationName}
          maxPrice={maxPrice}
          updateLocation={(location) => {
            setCbsa(location.cbsaname)
            if (location?.city && location?.state) {
              setLocationName(`${location?.city}, ${location?.state}`)
            }
          }}
          showPriceFilter={true}
          updateMaxPrice={(price) => {
            setMaxPrice(parseInt(price))
          }}
        />
        {/* <div className={styles.categoriesSection}>
          <h2>More Categories</h2>
          <div className={styles.categoryContainer}>
            <div
              className={styles.categoryBorder}
              onClick={() => navigate("/events/event_type=theater")}
            >
              <div className={styles.category}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/theater.png"
                  alt="Theater"
                />
                <h3>Theater</h3>
              </div>
            </div>
            <div
              className={styles.categoryBorder}
              onClick={() => navigate("/events/event_type=theater")}
            >
              <div className={styles.category}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/college+sports.png"
                  alt="College Sports"
                />
                <h3>College Sports</h3>
              </div>
            </div>
            <div
              className={styles.categoryBorder}
              onClick={() => navigate("/events/event_type=theater")}
            >
              <div className={styles.category}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/podcasts.png"
                  alt="Podcasts"
                />
                <h3>Podcasts</h3>
              </div>
            </div>
            <div
              className={styles.categoryBorder}
              onClick={() => navigate("/events/event_type=comedy")}
            >
              <div className={styles.category}>
                <img
                  src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/comedy.png"
                  alt="Comedy"
                />
                <h3>Comedy</h3>
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles.betterTixContainer}>
          <h1>We believe in better ticketing.</h1>
          <p>
            Today, that means transparent pricing on all the events you love.
          </p>
          <p>But tomorrow? Expect more than just a ticket.</p>
          {/* <a
            href="https://faint-ballcap-e83.notion.site/WELCOME-TO-XP-6176fd2899b84ea48c7532558594eea4"
            target="_blank"
            rel="noreferrer"
          >
            <Button rightIcon={<ArrowUpRight />} rm>
              Our Mission
            </Button>
          </a> */}
        </div>
        <video
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          className={classNames(styles.heroVideo, styles.bar)}
          id="bg-bar"
        >
          <source
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Bar_N.mp4"
            type="video/mp4"
          />
        </video>
        <picture>
          <source
            srcset="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Bar_N_Flipped.avif"
            type="image/avif"
          />
          <img
            src="https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/xp+stills+compressed/Bar_N_Flipped.png"
            id="bg-bar-fallback"
            alt="bar"
            className={classNames(
              styles.heroVideo,
              styles.bar,
              styles.fallbackImage
            )}
          />
        </picture>
      </Layout>
    </div>
  )
}

export default NewVelvet

