import React, {useEffect} from "react"
import { createRoot } from "react-dom/client"
import { useLocation } from 'react-router-dom';
import { Provider as ReduxProvider } from "react-redux"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import ReactPixel from "react-facebook-pixel"
import WalletContext from "./contexts/WalletContext"
import { store } from "./store"
import "./css/global.scss"

// routes
import VelvetRope from "./Home/NewVelvet"

import HolderNFTs from "./Holder/NFTs"
import HolderNFT from "./Holder/NFT"
import CoinbasePayTest from "./CoinbasePayTest"

import EventList from "./Events/EventList"
import EventPage from "./Events/EventPage"
import ArtistPage from "./Artist/ArtistPage"
import VenuePage from "./Venue/VenuePage"
import CheckoutPage from "./Checkout/CheckoutPage"
import AccountPage from "./Account/AccountPage"
import RewardPage from "./Account/RewardPage"
import NoMatch from "./Home/NoMatch"
import CCTPPage from "./Account/CCTPPage"
import Redeem from "./Redeem/Redeem"
import CategoryPage from './Events/CategoryPage'

import * as Sentry from "@sentry/react"
import LogRocket from "logrocket"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import Derby from "./Derby/Derby"
import SearchResults from "./Events/SearchResults"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

var originalFetch = window.fetch
window.fetch = function (input, init) {
  if (
    input &&
    input.indexOf(process.env.REACT_APP_HNGR_API) === -1 &&
    input.indexOf(process.env.REACT_APP_EXPRESS_API) === -1
  ) {
    return originalFetch(input, init)
  }
  if (!init) {
    init = {}
  }
  if (!init.headers) {
    init.headers = new Headers()
  }

  if (process.env.REACT_APP_SKIN) {
    if (init.headers instanceof Headers) {
      init.headers.append("XP-SKIN", process.env.REACT_APP_SKIN)
    } else if (init.headers instanceof Array) {
      init.headers.push(["XP-SKIN", process.env.REACT_APP_SKIN])
    } else {
      init.headers["XP-SKIN"] = process.env.REACT_APP_SKIN
    }
  }

  return originalFetch(input, init)
}

const SENTRY_IGNORE_ERRORS = [
  "Failed to fetch",
  "XMLHttpRequest",
  "unknown",
  "extension",
]

if (process.env.REACT_APP_HNGR_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_HNGR_SENTRY_DSN,
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    beforeSend(event, hint) {
      try {
        event.extra.LOGROCKET_SESSION_URL = window.LOGROCKET_SESSION_URL
        return event
      } catch (err) {
        return event
      }
    },
  })
}

if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET)
  LogRocket.getSessionURL((sessionURL) => {
    window.LOGROCKET_SESSION_URL = sessionURL
  })
}

const App = () => {
  let CheckoutComponent = CheckoutPage

  const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  }

  const location = useLocation();

  useEffect(() => {
    setTimeout(()=>{
      window.prerenderReady = true; 
    }, 30000)
  }, [location]);


  ReactPixel.init("1514233069351079", advancedMatching, options)

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Routes>
        <Route path="/" element={<VelvetRope />} />

        <Route
          path="/mike"
          render={() => <Navigate to="/campaign/saunders3000" />}
        />

        <Route path="/campaign/:campaign_name" element={<VelvetRope />} />

        <Route path="/c/:campaign_name" element={<VelvetRope />} />

        <Route path="/holder/tickets" element={<HolderNFTs />} />

        <Route path="/holder/ticket/:mint" element={<HolderNFT />} />

        <Route path="/events" element={<EventList />} />

        <Route path="/events/cbsa/:cbsa" element={<EventList />} />

        <Route path="/events/search/:query" element={<SearchResults />} />

        <Route path="/events/city/:city/:state" element={<EventList />} />

        <Route path="/events/:category/:subcategory" element={<CategoryPage />} />

        <Route path="/event/:event_id" element={<EventPage />} />

        <Route
          path="/checkout/:event_id/:ticket_group_vendor_id"
          element={<CheckoutComponent />}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_vendor_id/tickets"
          element={<CheckoutComponent />}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_vendor_id/delivery"
          element={<CheckoutComponent />}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_vendor_id/payment"
          element={<CheckoutComponent />}
        />

        <Route
          path="/checkout/:event_id/:ticket_group_vendor_id/success"
          element={<CheckoutComponent />}
        />

        <Route path="/artist/:artist_id/details" element={<ArtistPage />} />
        <Route path="/artist/:artist_id" element={<ArtistPage />} />

        <Route path="/venue/:venue_id" element={<VenuePage />} />

        <Route path="/account" element={<AccountPage />} />

        <Route path="/account/rewards" element={<RewardPage />} />

        <Route path="/account/cctp" element={<CCTPPage />} />

        <Route path="/coinbase" element={<CoinbasePayTest />} />

        <Route path="/redeem" element={<Redeem />} />

        <Route path="/g/derby" element={<Derby />} />

        <Route path="/g/derby/play" element={<Derby />} />

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  )
}

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <WalletContext>
        <App />
      </WalletContext>
    </BrowserRouter>
  </ReduxProvider>
)
