import classNames from "classnames"
import styles from "./ArtistCard.module.scss"
import { useNavigate } from "react-router-dom"

const ArtistCard = ({ className, event, performer }) => {
  const navigate = useNavigate()

  return (
    <div
      className={styles.cardBorder}
      onClick={() => {
        navigate(
          event
            ? `/artist/${event.performer_id}`
            : `/artist/${performer.performer_id}`
        )
      }}
    >
      <div className={classNames(styles.cardContainer, className)}>
        <div className={styles.card}>
          <picture className={styles.cardImg}>
            <source srcset={event?.image_avif} type="image/avif" />
            <source srcset={event?.image_webp} type="image/webp" />
            <img
              src={
                event
                  ? event.image
                  : performer?.default_img
                  ? performer.default_img
                  : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
              }
              className={styles.cardImg}
              alt={event ? event.title : performer?.name}
            />
          </picture>
          <div className={styles.cardBottom}>
            <div className={styles.titleContainer}>
              <h3>{event ? event?.performer_name : performer?.name}</h3>
            </div>

            {event && (
              <div className={styles.priceContainer}>
                <span>All-In</span>
                <p className={styles.priceDescription}>
                  <span className={styles.price}>
                    ${Math.ceil(event?.min_ticket_price / 100)}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ArtistCard
