import classNames from "classnames"
import styles from "./VenueCard.module.scss"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"

const VenueCard = ({ className, venue }) => {
  const [fullVenue, setFullVenue] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (venue) {
      getVenue()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getVenue = async () => {
    let url = `${process.env.REACT_APP_HNGR_API}/api/stagehand/venue-by-id`
    let params = { venue_id: venue.venue_id }
    let resp = await fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
    resp = await resp.json()
    setFullVenue(resp.venue)
  }

  return (
    <div
      className={styles.cardBorder}
      onClick={() => {
        navigate(`/venue/${fullVenue.venue_id}`)
      }}
    >
      <div className={classNames(styles.cardContainer, className)}>
        <div className={styles.card}>
          <picture className={styles.cardImg}>
            <source srcset={fullVenue?.image} type="image/avif" />
            <source srcset={fullVenue?.image} type="image/webp" />
            <img
              src={
                fullVenue?.image
                  ? fullVenue.image
                  : "https://hngr-icons.s3.amazonaws.com/supperclub/ticketdex/Event+Card+Image.png"
              }
              className={styles.cardImg}
              alt={fullVenue?.name}
            />
          </picture>
          <div className={styles.cardBottom}>
            <div className={styles.titleContainer}>
              <h3>{fullVenue?.name}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VenueCard
